.form-control[disabled] {
  opacity: .8
}

.form-container-adjust {
  max-width: 68em;
  margin-left: 0;

  h2 {
    padding-bottom: 20px;
  }

  & .form-inline {
    padding-bottom: 10px;
  }
}

.form-password {
  position: relative;
  font-size: 14px;
  height: auto;
  padding-bottom: 15px;
  padding-top: 5px;

  input {
    background: #ffffff;
    border: 1px solid #efeded;
  }
}

.form-area {
  position: relative;
  font-size: 14px;
  height: auto;
  padding-bottom: 15px;
  padding-top: 10px;

  textarea {
    background: #ffffff;
    border: 1px solid #efeded;
  }
}

.form-project {
  position: relative;
  font-size: 14px;
  height: auto;
  padding-bottom: 15px;
  padding-top: 5px;

  select {
    background: #ffffff;
  }
}

.form-checkbox {
  padding-left: 5px;
  padding-bottom: 15px;
}

.form-top {
  padding-top: 15px;
}

.form-width {
  width: 330px;
}

.form-show-hide-password {
  margin-left: 15px;
  margin-right: 10px;
}

.form-inner-row {
  @form-inner-gutter: 3px;

  .col-first {
    padding-right: @form-inner-gutter;
  }

  .col-mid {
    padding-left: @form-inner-gutter;
    padding-right: @form-inner-gutter;
  }

  .col-last {
    padding-left: @form-inner-gutter;
  }
}

.form-image-uploader {
  input {
    display: none;
  }

  .image-preview {
    span {
      display: none;

      &:first-child:last-child {
        display: inline;
      }
    }

    img {
      max-width: 200px;
      height: 200px;
      border-radius: 5px;
    }

    margin-bottom: 10px;

  }

  &.multi .image-preview {
    ul {
      list-style-type: none;
      width: 100%;

      li {
        display: inline-block;
        width: 25%;
      }

      padding-bottom: 10px;
    }

    img {
      padding: 0;
    }
  }

  .form-inline & {
    display: inline-block;

    .image-preview {
      display: inline-block;

      img {
        width: auto;
        max-height: 34px;
        padding: 0 5px;
        display: inline-block;
      }
    }
  }
}

.form-file-input {
  .file-input {
    display: none;
  }
}

.form-inline {
  .alert {
    display: inline-block;
    margin: 0;
  }
}

.block-entry {
  margin-bottom: 20px;

  .block-position-column {
    margin-left: 15px;
    padding-right: 0;
  }

  .block-panel-column {
    padding-left: 0;

    .block-panel {
      border-top-left-radius: 0;
      margin-bottom: 0;
      border-bottom-right-radius: 0;

      .form-inline {
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }
  }

  .inner-position-column {
    padding-right: 0;
  }

  .inner-panel-column {
    padding-left: 0;

    .panel {
      margin-bottom: 0;
      border-top: none;
      border-radius: 0;
    }
  }

  &.expanded {
    .expand-block-btn {
      display: none;
    }
  }

  &.collapsed {
    .collapse-block-btn, .inner-entries, .bottom-controls {
      display: none;
    }
  }

  .inner-entry {
    &:last-child {
      .inner-panel-column .panel {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }

    &.expanded {
      .expand-inner-btn {
        display: none;
      }
    }

    &.collapsed {
      .collapse-inner-btn, .inner-contents {
        display: none;
      }

      .panel .form-inline {
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }
  }
}


.well {
  margin-top: 5px;

  img {
    margin-bottom: 10px;
  }
}

//recreate local variables
@input-height-base: 34px;
@input-height-small: 30px;
@input-height-large: 46px;
@padding-base-horizontal: 12px;
@grid-gutter-width: 30px;
@screen-sm-min: 768px;


.has-feedback {
  // Right Icon Input Padding improvements
  // when the padding mirrors the input height, it makes the icon look like it
  // occupies a square on the side of the control
  .form-control {
    padding-right: @input-height-base;
  }

  .form-control.input-sm,
  &.form-group-sm .form-control {
    padding-right: @input-height-small;
  }

  .form-control.input-lg,
  &.form-group-lg .form-control {
    padding-right: @input-height-large;
  }
}

.has-feedback-left {
  // Left Icon Input Padding
  .form-control {
    //reset changes to right
    padding-right: @padding-base-horizontal;
    padding-left: @input-height-base;
  }

  .form-control.input-sm,
  &.form-group-sm .form-control {
    padding-left: @input-height-small;
  }

  .form-control.input-lg,
  &.form-group-lg .form-control {
    padding-left: @input-height-large;
  }
}

.has-feedback-right {
  // Left Icon Input Padding
  .form-control {
    //reset changes to right
    padding-right: @padding-base-horizontal;
    padding-left: @input-height-base;
  }

  .form-control.input-sm,
  &.form-group-sm .form-control {
    padding-left: @input-height-small;
  }

  .form-control.input-lg,
  &.form-group-lg .form-control {
    padding-left: @input-height-large;
  }
}

.has-feedback-right .form-control-feedback {
  left: 0;
}

// Left Align Icon
.has-feedback-left .form-control-feedback {
  left: 0;
}

// Set Icon Line Height for vertical centering
// If using font awesome, library is likely to come after bootstrap library and
// override line-height set here.  I'm inclined to use !important to make it
// easier for these line heights to persist in the case the icons are being
// used explicitly within an input box.
.form-control-feedback {
  line-height: @input-height-base !important;
}

.input-sm + .form-control-feedback,
.form-horizontal .form-group-sm .form-control-feedback {
  width: @input-height-small;
  height: @input-height-small;
  line-height: @input-height-small !important;
}

.input-lg + .form-control-feedback,
.form-horizontal .form-group-lg .form-control-feedback {
  width: @input-height-large;
  height: @input-height-large;
  line-height: @input-height-large !important;
}


// Move Icon to top
// Added to first selector.  If the form-control-feedback is not a sibling,
// but a child of a sibling, we want to move it up when there is no label.
.has-feedback label {
  &.sr-only ~ .form-control-feedback,
  &.sr-only ~ div .form-control-feedbackß {
    top: 7px;
  }
}

.form-inline {
  @media (min-width: @screen-sm-min) {
    // Allow left aligned icon in inline form
    .inline-feedback {
      // enable absolute positioning
      position: relative;
      display: inline-block;
    }

    // Re-override the feedback icon.
    .has-feedback .form-control-feedback {
      top: 7px;
    }
  }
}

.form-horizontal {
  .has-feedback-left .form-control-feedback {
    left: (@grid-gutter-width / 2);
  }
}

.form-control-feedback {
  top: 7px;
}

.ui-datepicker-trigger {
  display: none !important;
}

.ui-widget-header {
  color: black;
}

.ui-state-default:not(select), .ui-widget-content .ui-state-default:not(select), .ui-widget-header .ui-state-default, .ui-button, html .ui-button.ui-state-disabled:hover, html .ui-button.ui-state-disabled:active {
  background: #05b2c5;
  border: none;
  color: white;
}

.ui-widget select {
  background: #ECF0F5 !important;
  color: #555555 !important;
}

.ui-state-highlight, .ui-widget-content .ui-state-highlight, .ui-widget-header .ui-state-highlight {
  border: 1px solid #bdc3c7;
  background: #ecf0f1;
  color: #05b2c5;
}

.ui-widget {
  font-family: Lato, Helvetica, Arial, sans-serif;
  font-size: 0.9em;
}

.ui-widget-header {
  border: 1px solid #aaaaaa;
  background: #2c3e50;
  color: #222222;
  font-weight: bold;
}

.ui-widget.ui-widget-content {
  background: white;
  border: 1px solid #333333;

}

.ui-state-active, .ui-widget-content .ui-state-active {
  background: #0060b1 !important;
}

.ui-state-hover, .ui-widget-content .ui-state-hover, .ui-widget-header .ui-state-hover {
  border: 1px solid #2293f7;
  background: #2293f7;
  font-weight: bold;
  color: #ffffff;
}

.btn-datepicker {
  border-radius: 0px 4px 4px 0px;
}

.col-auto {
  position: relative;
  min-height: 1px;
  padding-left: 0;
  padding-right: 0;
  float: left;
  width: auto;
}
