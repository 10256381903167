@import "new-design/settings";
@import "../../../node_modules/bootstrap/less/bootstrap";
@import "selectize/selectize.bootstrap3";
@import "full-page";
@import "tables";
@import "forms";
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700,300italic,400italic,600italic);
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,700');
//@import url(http://localhost:8888/css/fonts/futura-lt-bold.tff);

@header-height: 50px;
@sidebar-entry-height: 50px;
@sidebar-width: 300px;

@font-face {
  font-family: 'Futura Lt BT Light';
  font-style: normal;
  font-weight: normal;
  src: local('Futura Lt BT Light'), url('/css/fonts/futura_lt_bt_light-webfont.woff') format('woff');
}

@font-face {
  font-family: 'Futura Lt Bold';
  font-style: normal;
  font-weight: normal;
  src: local('☺'), url('/css/fonts/futura-lt-bold.woff') format('woff'); // local('Futura Lt Bold'),
}

@font-face {
  font-family: 'SofiaProMedium';
  font-style: normal;
  font-weight: normal;
  src: local('SofiaProMedium'), url('/css/fonts/SofiaProMedium.woff') format('woff');
}

@font-face {
  font-family: 'SofiaProSemiBold';
  font-style: normal;
  font-weight: normal;
  src: local('☺'), url('/css/fonts/SofiaProSemiBold.woff') format('woff'); // local('Futura Lt Bold'),
}

* {
  margin: 0;
  padding: 0;
}

body.admin {
  .masthead {
    text-align: center;
    z-index: 100;
    height: @header-height;
    font-size: 45px;
    img {
      max-height: @header-height;
    }
    .subheader {
      color: grey;
      font-size: 30px;
      font-weight: 300;
    }
    .top-buttons {
      float: right;
      line-height: @header-height;
      .nav-wrapper {
        display: inline-block;
        vertical-align: middle;
        .nav {
          display: inline-block;
          font-size: @font-size-base;
          line-height: @line-height-base;
        }
      }
    }
  }
  .content-wrapper {
    //&.viewport {
      //position: absolute;
      //height: 100%;
      //padding-bottom: 150px;
      //width: 100%;
      //padding-right: 20px;
    //}
  }
}

.navbar {
  &-default {
    background-color: #048492;
    border-color: transparent !important;
  }
  &-default &-toggle {
    border-color: transparent !important;
    &:hover, &:focus, &:active {
      background-color: transparent !important;
    }
    & .icon-bar {
      background-color: white;
    }
  }
  &-default &-brand {
    color: white;
    font-family: 'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: subpixel-antialiased;
    &:hover, &:focus {
      color: white;
    }
  }
  @media (min-width: 768px) {
    display: none;
  }
}

.sidebar {
  font-family: "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: subpixel-antialiased;
  font-weight: 300;
  letter-spacing: 1px;
  position: fixed;
  left: 0;
  height: 100%;
  background-color: #05b2c5; //#2E3E4E; //#222d32;
  color: white;
  padding: 0;
  -webkit-transition: margin 200ms ease-out;
  -moz-transition: margin 200ms ease-out;
  -o-transition: margin 200ms ease-out;
  transition: margin 200ms ease-out;
  display: block;
  z-index: 7;
  min-width: 260px;
  /*@media(max-width: 767px){
      overflow-y: auto;
  }*/
  .top {
    padding: 20px;
    width: 100%;
    .clearfix;
    span {
      float: left;
      i {
        margin-right: 10px;
      }
    }
    a {
      float: right;
      color: white;
    }
    label {
      padding-right: 10px;
    }
  }
  .top + .top {
    padding-top: 0;
  }

  .title {
    padding: 10px 0 10px 10px;
    background-color: #048492; //#0E2E40;
    height: 90px;
    @media (max-width: 767px) {
      margin-top: 55px;
    }

    .user-image, .user-info, .user-options {
      display: table-cell;
      vertical-align: top;
      img {
        display: block;
        width: 30px;
        margin-right: 15px;
        margin-top: 10px;
      }
      .circle-text {
        width: 30px;
        margin-right: 15px;
        margin-top: 10px;
        font-size: 24px;
        height: auto;
      }
    }

    .user-info {
      .person {
        font-size: 21px;
        line-height: 30px;
        height: 30px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 175px;
      }
    }

    .user-options {
      float: right;
      a.button {
        color: #fff;
        font-size: 2em;
        line-height: 2.3;
      }
      ul a {
        padding-left: 5px;
      }
      .dropdown-menu {
        margin-top: -15px;
      }
    }

  }
  .menu-items {
    overflow-y: auto;
    position: absolute;
    left: 0;
    right: 0;
    top: 90px;
    bottom: 0;

    @media (max-width: 767px) {
      margin-top: 60px;
    }

  }

  .sidemenu {
    display: flex;
    flex-direction: column;
    min-height: 100%;
  }

  ul:not(.dropdown-menu) {
    flex: 1;
    @menu-item-padding: 15px;
    list-style-type: none;
    width: 100%;
    margin-bottom: 30px;

    .list-block() {
      display: block;
      padding-left: @menu-item-padding;
      line-height: @sidebar-entry-height;
    }
    li {
      color: white; //#9d9d9d;
      a {
        .list-block;
        color: white; //#CACFD4;
        text-decoration: none;
        line-height: 35px;
        img {
          width: 24px;
          margin-left: -6px;
        }
        span {
          margin-left: 5px;
          display: inline-block;
          /*@media (max-width: 767px) {
              &.collapse {
                  display: none !important;
              }
          }*/
        }
      }
      &:not(.selected) {
        padding-left: 8px;

        &:hover > :first-child {
          color: white;
          background: #048492;
          border-left: 8px solid #023f45;
          margin-left: -8px;
        }
      }
      &.selected > :first-child {
        color: white;
        background: #048492; //#283644;//#1e282c;
        border-left: 8px solid #023f45;
      }
      &.expandable {
        & > :first-child {
          .list-block;
        }
        cursor: pointer;
        .expandable-toggle-features(@level) {
          ul li > :first-child {
            padding-left: @menu-item-padding * (@level + 1);
          }
          .closed-icon {
            display: inline-block;
          }
          .opened-icon, ul {
            display: none;
          }
          &.opened {
            .closed-icon {
              display: none;
            }
            .opened-icon {
              display: inline-block;
            }
            ul {
              display: block;
            }
          }
        }
        .expandable-toggle-features(1);
        .expandable {
          .expandable-toggle-features(2);
        }
      }
      &.header {
        font-family: "Futura Lt Bold";
        -webkit-font-smoothing: subpixel-antialiased;
        //font-weight: bold;
        font-size: 18px !important;
        text-transform: uppercase;
        overflow: hidden;
        text-overflow: clip;
        padding: 10px 25px 10px 15px;
        white-space: nowrap;
        /*
        @media (max-width: 767px) {
            display: none !important;
        }*/
      }
    }
    hr {
      border-top: 1px solid #283644;
    }
  }
  .sidebar-footer {
    font-family: 'Futura Lt Bold';
    -webkit-font-smoothing: subpixel-antialiased;
    letter-spacing: 1.8px;
    word-spacing: 5px;
    font-size: 30px !important;
    font-weight: 600;
    bottom: 0px;
    clear: both;
    display: block;
    text-align: center;
    color: white; //#859298;//#4b646f;
    width: 100%;
    @media (min-width: 768px) {
      padding-top: 45px;
    }

    /*
    @media (max-width: 767px) {
        display: none !important;
    }
    */
  }

  //Responsive
  /*
  @media (max-width: 767px) {
      &.collapse {
          -webkit-transition: margin 200ms ease-out;
          -moz-transition: margin 200ms ease-out;
          -o-transition: margin 200ms ease-out;
          transition: margin 200ms ease-out;
          width: 50px!important;
          z-index: 850;
          display: block;
      }
      &.extend {
          display: inline-block;
      }
  }*/
  @media (max-width: 767px) {
    &.collapse {
      display: none;
    }

    &.collapse.in {
      display: block;
    }
  }

}

.padding {
  margin-left: 60px;
  margin-right: 20px;

  display: flex;
  min-height: 100vh;
  flex-direction: column;

  @media (min-width: 768px) and (max-width: 1200px) {
    margin-left: 130px;
  }

  @media (max-width: 767px) {
    margin-left: 20px;
  }
}

.main-wrapper {
  flex: 1;
  margin-bottom: 10px;
  position: relative;

  &.no-overflow {
    overflow: hidden;
  }

  @media (max-width: 767px) {
    margin-top: 70px;
  }
}

.float-right {
  float: right;
}

.panel-adjust {
  max-width: 68em;
}

ul.logbook {
  height: 200px;
  overflow: auto;
}

.circle-text {
  text-transform: uppercase;
  max-width: 200px;
  height: 200px;
  //background-color: #90caf9;
  background-color: @colors-primary;
  border-radius: 5px;
  color: #fff !important;
  text-align: center;
  font-size: 130px;
  .small {
    font-size: 12px;
  }
}

img.circle-text {
  background-color: transparent;
}

.box {
  -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.20);
  -moz-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.20);
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.20);
  background-color: #fff;
  position: relative;
  margin-bottom: 1.5rem;
  display: box;

  .circle-text {
    margin-left: auto;
    margin-right: auto;
  }

  &-header {
    position: relative;
    width: 100%;
    height: 200px;
    background-color: #f3f3f3;

    .background {
      width: 100%;
      height: 100%;

      img {
        &.no-image {
          width: auto;
          height: 60px;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }

    .buttons {
      position: absolute;
      top: 0;
      opacity: 0;
      transition: background 0.2s linear;
      width: 100%;
      height: 100%;
      font-size: 14px;

      &:hover {
        opacity: 1;
        background-color: rgba(0, 0, 0, 0.7);
      }

      .btn {
        color: white;
        padding: 3px 3px 6px;
        position: absolute;

        i {
          vertical-align: middle;
        }

        &.center-center {
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        &.left {
          top: 8px;
          left: 8px;
        }

        &.center {
          top: 8px;
          left: 50%;
          -webkit-transform: translateX(-50%);
          -moz-transform: translateX(-50%);
          -ms-transform: translateX(-50%);
          -o-transform: translateX(-50%);
          transform: translateX(-50%);
        }

        &.right {
          top: 8px;
          right: 8px;
        }

        &:hover {
          color: @colors-primary;
        }
      }
    }
  }

  &-body {
    padding: 20px;

    a:hover {
      color: @colors-primary;
    }
  }

  .label {
    display: inline-block;
    font-size: 90%;
    text-align: center;
    font-weight: normal;
    width: 100%;
  }

  th, td {
    vertical-align: middle;
    text-align: center;
    overflow: hidden;
  }

  hr {
    margin-bottom: 10px;
  }

  table {
    margin-bottom: 15px;
  }

  &-footer-left {
    text-align: left;
    margin-right: 10px;
    margin-bottom: 10px;
    float: left;
  }

  &-footer-center {
    margin-right: 10px;
    margin-bottom: 10px;
    text-align: center !important;
  }

  &-footer-right {
    margin-right: 10px;
    margin-bottom: 10px;
    text-align: right;
  }

}

.box-wrapper {
  background-color: white;
  margin-top: 15px;
  border-top: 3px solid #65c9ca;
  border-bottom: 2px solid #65c9ca;
  min-height: 150px;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.15);
  font-family: 'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: subpixel-antialiased;
  margin-bottom: 25px;
  h2 {
    text-align: center;
    margin-top: 0px;
    color: #777;
    font-weight: 300;
  }
  &-header {
    background-color: #eeeeee;
    padding: 5px;
    margin-bottom: 10px;
  }
}

.empty-text {
  font-size: 16px;
  text-align: center;
  color: #8c8c8c;
  opacity: 0.5;

  .small {
    font-size: 14px;
    color: #f2f2f2
  }
}

.files {
  &-drop {
    background-color: #2E3134;
    padding: 15px 50px;
    margin-bottom: 30px;
    border: 2px dashed #0087F7;
    border-radius: 5px;
    background: white;
    text-align: center;
    font-size: 16px;
    margin-bottom: 2px;
    input {
      display: none;
    }
  }
  button.btn-upload-file {
    margin-top: 0px;
    text-align: center;
    width: 100%;
    height: 50px;
    font-size: 18px;
  }
  margin-bottom: 25px;
}

.drag-overlay {
  opacity: 0.7;
  background: rgba(172, 172, 172, 1);
  width: 100%;
  height: 100%;
  z-index: 10;
  top: 0;
  left: 0;
  position: fixed;
  text-align: center;

  .center {
    top: 50%;
    position: relative;
    background: #444444;
    margin-top: -60px;
  }
  p {
    padding: 25px;
    color: white;
    text-align: center;
    font-size: 1.5em;
    font-style: italic;
    font-weight: normal;
  }
  display: none;
  pointer-events: none;
}

ul.upload-list, ul.added-files-list {
  list-style: none;
  margin: 0;

  li {
    border-top: 1px solid #3d4043;
    padding: 5px;
    height: 52px;
    flex-direction: row;
    display: flex;

    &:not(:first-child) {
      margin-top: 10px;
    }

    div.icon {
      font-size: 2em;
    }
    p {
      overflow: hidden;
      white-space: nowrap;
      font-size: 16px;
      font-weight: bold;
      flex: 1;
      height: 80px;
      margin-left: 10px;

      i.file-size {
        font-weight: normal;
        font-style: normal;
        color: #7f7f7f;
        display: block;
      }
    }

    div.right-text {
      margin-left: 10px;
    }
    button.btn-del {
      height: 30px;
      padding: 5px 20px;

      i {
        color: white;
      }
    }
  }
}

ul.upload-list {
  margin: 0 -30px;
}

.readable {
  font-size: 0.9em;
}

.bs-badge {
  font-size: 13px;
  font-weight: 700;
  line-height: 19px;
  display: inline-block;
  min-width: 20px;
  height: 20px;
  padding: 0 4px 4px;
  text-align: center;
  vertical-align: baseline;
  white-space: nowrap;
  border-color: #cf4436;
  background: #e74c3c;
  border-radius: 10px;
}

.notification-placeholder {
  @media (max-width: 768px) {
    display: none;
  }
  @media (min-width: 769px) {
    display: block;
  }
}

.notifications-list-group {
  margin-bottom: 20px;
  padding-left: 0px;
  overflow: auto;
  overflow-x: hidden;
  height: 100%;

  .list-group-item {
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: transparent;
    transition: all 0.2s ease-out 0s;
    background-color: #fcfcfd;
    cursor: pointer;
    transition: background-color 0.3s;
    .notification-table-item {
      margin-top: 0px;
      padding-left: 10px;
      overflow: hidden;

      p {
        color: rgb(80, 91, 114);
        font-size: 12px;
        margin: 8px 0px 0px;
      }

      .media-left {
        padding-right: 10px;
        display: table-cell;
        vertical-align: top;
      }

      .media-body {

        span {
          font-size: 11px;
          color: rgb(144, 155, 177);
          margin-top: -2px;
        }

        h5.notification-heading {
          margin-bottom: 3px;
          font-weight: normal;
          font-size: 12px;
          color: rgb(80, 91, 114);
          margin-top: 0px;
        }

        h5:not(.notification-heading) {
          color: rgb(59, 67, 84);
          font-size: 13px;
          font-weight: 500;
          margin: 0px;

        }

        h5.notification-subject {
          transition: font-weight 0.3s;
        }

        h5.bold {
          font-weight: bold;
        }

      }

      .media-left, .media-right, .notification-body {
        display: table-cell;
        vertical-align: top;
      }

    }

    &:hover {
      background-color: #e4e7ec;
    }

  }

  .selected {
    background-color: #e4e7ec;
  }

  @media (max-width: 768px ) {
    max-width: 95%;
  }
}

.comment-content {
  margin-left: 25px;
}

.comment-body {
  margin-left: 30px;
  div.buttons {
    float: right;
    line-height: 50px;
    margin-top: -50px;
  }
}

ul.project-list {
  @media (min-width: 768px ) {
    column-count: 2;
  }

  li.project-item {
    display: inline-block;
    position: relative;
    width: 100%;
    padding: 6px;
    transition: background-color 0.3s;
    border-bottom: 1px solid rgba(120, 130, 140, .065);
    cursor: pointer;

    &:hover {
      background-color: #e4e7ec;
    }

    a {
      text-decoration: none;
    }

    a.list-left {
      float: left;
      padding-right: 1rem;
    }

    .circle-text {
      width: 30px;
      margin-right: 15px;
      font-size: 24px;
      height: auto;
    }
  }
}

.btn-group .btn + .btn, .btn-group .btn + .btn-group, .btn-group .btn-group + .btn, .btn-group .btn-group + .btn-group {
  margin-left: 0;

}

table {
  transition: all 0.2s ease-out 0s;
}

.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: #f7f7f7;
}

.table-bordered {
  border: 2px solid #f7f7f7;
}

.table-bordered > thead > tr > th, .table-bordered > tbody > tr > th, .table-bordered > tfoot > tr > th, .table-bordered > thead > tr > td, .table-bordered > tbody > tr > td, .table-bordered > tfoot > tr > td {
  border: 2px solid #f7f7f7;
}

.input-group.disabled {
  opacity: 0.5;
}

.input-group-btn > .btn {
  border-radius: 0 4px 4px 0;
}

@media (max-width: 991px) {
  .btn-group > .btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    padding-right: 8px;
  }

  .btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    padding-right: 6px;
  }

  .btn-group > .btn + .dropdown-toggle {
    padding-left: 7px;
    border-top-right-radius: 0;
  }
}

.dt-table .btn-group a i.larger {
  font-size: larger;
}
