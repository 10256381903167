.selectize-control.plugin-remove_button {
	[data-value] {
		position: relative;
		padding-right: 24px !important;
	}
	[data-value] .remove {
		z-index: 1; /* fixes ie bug (see #392) */
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		width: 17px;
		text-align: center;
		font-weight: bold;
		font-size: 12px;
		color: inherit;
		text-decoration: none;
		vertical-align: middle;
		display: inline-block;
		padding: @selectize-padding-item-y 0 0 0;
		border-left: 1px solid @selectize-color-item-border;
		.selectize-border-radius(0 2px 2px 0);
		.selectize-box-sizing(border-box);
	}
	[data-value] .remove:hover {
		background: rgba(0,0,0,0.05);
	}
	[data-value].active .remove {
		border-left-color: @selectize-color-item-active-border;
	}
	.disabled [data-value] .remove:hover {
		background: none;
	}
	.disabled [data-value] .remove {
		border-left-color: lighten(desaturate(@selectize-color-item-border, 100%), @selectize-lighten-disabled-item-border);
	}
}