@colors-primary: #180124;
@colors-secondary: @colors-accent;
@colors-accent: #009BFF;
@colors-fade-start: #8000FF;
@colors-fade-end: #FF00EB;
@colors-shade: #15011f;
@colors-contrast-background: #2c0648;
@colors-dark-pattern-background: #2c0648;

@colors-text-primary: #000000;
@colors-text-secondary: #8F7B9E;
@colors-text-input: #000000;
@colors-text-input-placeholder: #AFAFAF;
@colors-text-accent: @colors-accent;

@colors-dim: #BBB3C0;
@colors-dropdown: #333333;

@color-dark: #0b292d; // hovered, active state URLs
@color-gray: #9B9B9B; // base color URLs
@color-dark-gray: #6d6d6d;
@color-darkest-gray: #494949;
@color-light-gray: #EBEBEB;
@color-light:#F7F7F7;
@color-base: #ffffff;
@color-avatar-background:#1E8490;

@color-alert: #FE5E46;
@color-okay: #48DE5E;

@import url('https://fonts.googleapis.com/css?family=Roboto:300,400');
@font-primary: work sans, sans-serif;
@font-size: 14px;

@radius-small: 5px;

@gutter: 1em/@font-size*20; // 20px
@space: 40px; // 40px;

@large: 48px;
@medium: 32px;
@small: 24px;