/* primarily used for login page */
body.full-page {
    background-color: #2c0648;
    background-image: url(/img/sst-pattern-dark.png);
    background-repeat: repeat;
    background-position: left -99px top -26px;


    .full-page-wrapper {
        width: 100%;
        height: 100vh;
        min-height: 100vh;
        display: table;
        .full-page-wrapper-inner {
            display: table-cell;
            vertical-align: middle;
            .masthead {
                height: @header-height;
                font-size: 45px;
                background-repeat: no-repeat;
                margin-bottom:35px;
                padding-left: 40px;
            }
            .content-container {
                max-width: 300px;
                margin-left: auto;
                margin-right: auto;
                .form-group {
                    .form-control {
                        position: relative;
                        box-sizing: border-box;
                        font-size: 16px;
                        height: auto;
                        padding: 10px;
                    }
                }
                .form-login {
                    position: relative;
                    input[type=email] {
                        .border-bottom-radius(0);
                        margin-bottom: 10px;
                        &:focus {
                            z-index: 2;
                        }
                    }
                    input {
                        background: #ffffff;
                        border: 1px solid #f7f7f7;
                        margin: 20px auto;
                        border-radius: 0;
                    }
                    button {
                        margin-top: 5px;
                        border-radius: 0;
                        border-color: transparent;
                    }
                    .has-feedback-left .form-control-feedback {
                        left: 0;
                    }
                    .form-error {
                        top: -43px;
                        background: @brand-danger;
                        color: white;
                        font-size: 16px;
                        padding: 10px;
                        text-align: center;
                        width: 100%;
                        border-radius: 0;
                    }
                }
            }
        }
    }
    .login-wrapper {
        margin: 0 auto;
        width: 400px;
        padding-top: 20px;
        padding-bottom: 10px;
        border-radius: 0;
        background-color: @colors-primary;
        box-shadow: 30px 30px 50px @colors-shade;

        hr {
            width: 400px;
            height: 2px;
            border: 0;
            background: linear-gradient(90deg, @colors-fade-start, @colors-fade-end);
        }

        div.footer-link {
            padding-top: 30px;
            text-align: left;
            a {
                font-size: 14px;
                color: @colors-text-secondary;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    body.full-page .login-wrapper {
        width: 350px;
    }
    body.full-page .login-wrapper hr {
        width: 350px;
    }
    .navbar-header {
        padding-bottom: 25px;
    }
}
