.tables-above {
    .clearfix;
    .dataTables_filter {
        float: left;
        padding: 4px 0;
    }
    .dataTables_length {
        float: left;
        padding: 4px 0;
        margin-left: 20px;
        .selectize-control {
            width: 70px;
        }
    }
    .dataTables_paginate {
        float: right;
    }
}

.tables-below {
    .clearfix;
    .dataTables_info {
        float: left;
    }
    .dataTables_paginate {
        float: right;
    }
}

table th {
    .table-col-append {
        margin: -5px 0 -6px 90px;
        font-weight: normal;
    }
}

table tr {
    .row-buttons-wrapper {
        display: none;
        .row-buttons {
            margin-left: 10px;
            .btn {
                margin-top: -2px;
                margin-bottom: -2px;
            }
        }
    }
    &:hover .row-buttons-wrapper {
        display: inline-block;
    }
}

table.table-nowrap {
    td {
        overflow-x: hidden;
        white-space: nowrap;
    }
}

/* override some styling options of DataTables */
table.dataTable {
    border-width: 0;
    tr {
        border: 0;
        th {
            border-color: rgba(120,130,140,.045);
            border-right: 0;
            border-left: 0;
            border-bottom: 2px solid #eceeef;
            border-top: 1px solid #eceeef;
        }
        td {
            border: 0;
            div.center {
                text-align: center;
                span {
                    display:inline-block; 
                    text-align:center; 
                    width:50%; 
                    font-size:90%; 
                    font-weight:normal;
                }
            }
        }
    }
    thead {
        .sorting, .sorting_asc, .sorting_desc {
            &:after {
                font-family: 'FontAwesome-SST', 'FontAwesome';
            }
        }

        .center {
            text-align: center;
        }

        .sorting:after {
            content: "\f0dc"; /* sort */
        }

        .sorting_asc:after {
            content: "\f0de"; /* sort-asc */
        }

        .sorting_desc:after {
            content: "\f0dd"; /* sort-desc */
        }
    }
}
