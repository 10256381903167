.selectize-dropdown.plugin-optgroup_columns {
	.optgroup {
		border-right: 1px solid #f2f2f2;
		border-top: 0 none;
		float: left;
		.selectize-box-sizing(border-box);
	}
	.optgroup:last-child {
		border-right: 0 none;
	}
	.optgroup:before {
		display: none;
	}
	.optgroup-header {
		border-top: 0 none;
	}
}